<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <v-container>
      <v-row>
        <v-col cols="12" md="3">
          <v-radio-group v-model="filtro_listagem" row>
            <v-radio label="Meus protocolos" :value="usuid"></v-radio>
            <v-radio label="Protocolos Gerais" :value="0"></v-radio>
          </v-radio-group>
        </v-col>

        <v-col cols="12" md="5">
          <v-combobox
            v-model="filtro_filial"
            label="Selecione a Filial de Origem"
            :items="lista_filials"
            item-text="label"
            item-value="id"
          ></v-combobox>
        </v-col>
        <v-col cols="12" md="4">
          <v-combobox
            v-model="filtro_status"
            label="Selecione o status"
            :items="[
              'Todos',
              'concluido',
              'Próximo do Vencimento',
              'Atrasado',
              'cancelado',
              'andamento',
            ]"
          ></v-combobox> </v-col></v-row
    ></v-container>
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"
          >Protocolos Destinatarios</span
        >
        <span class="text-muted mt-3 font-weight-bold font-size-sm"
          >Lista de Protocos e status andamento</span
        >
      </h3>
      <!-- <div class="card-toolbar">
        <a href="#" class="btn btn-info font-weight-bolder font-size-sm mr-3"
          >New Arrivals</a
        >
        <a href="#" class="btn btn-danger font-weight-bolder font-size-sm"
          >Create</a
        >
      </div> -->
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <div class="tab-content">
        <!--begin::Table-->
        <div class="table-responsive" v-if="lista_protocolos_tf">
          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="lista_protocolos_tf"
            :items-per-page="perPage"
            :page.sync="currentPage"
            class="elevation-1"
          >
            <template v-slot:item.origin="{ item }">
              <div class="d-flex align-items-center">
                <div class="symbol symbol-30 symbol-light mr-4">
                  <span class="symbol-label">
                    <img
                      :src="item.origem.img"
                      class="h-100 align-self-end"
                      alt=""
                    />
                  </span>
                </div>
                <div>
                  <div>{{ item.origem.name }}</div>
                  <div class="text-caption">{{ item.origem.setor }}</div>
                  <div v-if="item.tf_filial" class="text-caption">
                    {{ item.filial.sigla }}
                  </div>
                </div>
              </div>
            </template>

            <template v-slot:item.destination="{ item }">
              <div class="d-flex align-items-center">
                <div class="symbol symbol-30 symbol-light mr-4">
                  <span class="symbol-label">
                    <img :src="item.img" class="h-100 align-self-end" alt="" />
                  </span>
                </div>
                <div>
                  <div>{{ item.nome_sobrenome }}</div>
                  <div class="text-caption">{{ item.setor.nome }}</div>
                  <div v-if="item.tf_filial" class="text-caption">
                    {{ item.tf_filial.sigla }}
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:item.status="{ item }">
              <span
                class="label label-lg label-inline px-2 pb-2"
                :class="{
                  'label-light-success': item.protocolo.status === 1,
                  'label-light-danger': item.protocolo.status === 0,
                  'label-light-warning': item.protocolo.status === 2,
                }"
              >
                {{ item.status1 }}
              </span>
            </template>
            <template v-slot:item.tipoAtendimento="{ item }">
              <span
                class="label label-lg label-inline px-2 pb-2"
                :class="{
                  'label-light-info': item.protocolo.tipo_atendimento === 1,
                  'label-light-primary': item.protocolo.tipo_atendimento === 2,
                }"
              >
                {{ item.status2 }}
              </span>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip
                v-for="(btn, index) in lista_btn"
                :key="index"
                top
                :color="btn.color"
              >
                <template
                  v-if="
                    true
                    // item.protocolo.status == 2 ? true : item.protocolo.status == 1 & item.usuario_id == usuid  ? true: false
                  "
                  v-slot:activator="{ on, attrs }"
                >
                  <template>
                    <template v-if="condicoes(usuid, btn, item)" >
                      <v-btn
                        v-if="lista_permissoes_filial[btn.permisao]"
                        fab
                        v-show="true"
                        outlined
                        class="mr-1 mb-1 mt-1"
                        @click="acoes(item, btn.button)"
                        :color="btn.color"
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <i :class="btn.icon"></i>
                      </v-btn>
                    </template>
                  </template>
                </template>

                <span>{{ btn.title }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </div>
        <!--end::Table-->
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>
  
  <script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import UsuarioService from "@/core/services/usuario.service";

export default {
  mixins: [fireAlert],
  data() {
    return {
      lista_btn: [
        {
          permisao: "u_Usuario",
          button: "editar",
          title: "Editar registro",
          color: "info",
          icon: "far fa-edit text-primary",
        },
        {
          permisao: "lock_Usuario",
          button: "exclusao",
          title: "Bloquear/Desbloquear registro",
          color: "error",
          icon: "fas fa-unlock-alt text-danger",
        },
        {
          permisao: "u_Usuario",
          button: "tf",
          title: "Transferência Protocolo",
          color: "warning",
          icon: "fas fa-exchange-alt text-warning",
        },
        {
          permisao: "u_Usuario",
          button: "aceitar",
          title: "Aceitar Protocolo",
          color: "success",
          icon: "fas fa-check text-success ",
        },
        {
          permisao: "u_Usuario",
          button: "arquivos",
          title: "Andamento Protocolo",
          color: "primary",
          icon: "fas fa-file-archive text-info ",
        },
      ],
      modal_create_regra: false,
      selectedUser: null,
      disableSaveModal: false,
      headers: [
        { text: "N/P", value: "protocolo.id", sortable: false },
        { text: "Origin", value: "origin", sortable: false },
        { text: "Destino", value: "destination", sortable: false },
        { text: "Tipo", value: "protocolo.tipo" },
        { text: "Assunto", value: "protocolo.assunto", width: "200px" },
        { text: "Status", value: "status" },
        { text: "Tipo Atendimento", value: "tipoAtendimento", sortable: false },
        { text: "", value: "actions", sortable: false },
      ],
      roleForm: {
        user_id: null,
        roles: [],
      },
      pesquisa: {
        tipo: "nome",
        texto: "",
      },
      currentPage: 1,
      perPage: 10,
      value: "",
      usuid: UsuarioService.getUsuario()["usuario_id"],
      userid: UsuarioService.getUsuario()["id"],
      filtro_listagem: null,
      filtro_status: null,
      lista_protocolos_tf: null,
      filtro_filial: null,
      loading: false,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Protocolos" }]);
    this.listar_protocolos();
  },
  created() {},
  computed: {
    protocolos_tf() {
      return this.$store.state.protocolo.lista_protocolos_tf;
    },
    lista_usuarios() {
      return this.$store.state.configUsuarios.lista_usuarios.filter((item) => {
        if (!this.pesquisa.texto) return true;
        return (
          String(item[this.pesquisa.tipo])
            .toLowerCase()
            .indexOf(this.pesquisa.texto.toLowerCase()) !== -1
        );
      });
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    lista_roles() {
      return this.$store.state.permissoes.lista_roles.map((role) => ({
        id: role.id,
        label: role.name,
      }));
    },

    mensagem_alert() {
      return this.$store.state.protocolo.mensagem_alert;
    },
    lista_filials() {
      let filiais = [];
      filiais.push({
        id: 0,
        label: "Todas",
      });
      this.$store.state.configEmpresa.lista_filials.map((cat) =>
        filiais.push({
          id: cat.id,
          label: cat.nome,
        })
      );
      return filiais;
    },
    // lista_protocolos_tf() {
    //   return this.$store.state.protocolo.lista_protocolos_tf;
    // },
  },

  watch: {
    filtro_listagem() {
      this.filtros();
    },
    filtro_status() {
      this.filtros();
    },
    filtro_filial() {
      this.filtros();
    },
  },

  methods: {
    condicoes(usuid, btn, item) {
      //   if  ( 1 == usuid){
      //     return true
      //  }
      //   ? true

      //   :
      if ((btn.button != "aceitar") & (btn.button != "arquivos")) {
        return (item.aceitar == 1) & (item.usuario_id == usuid) ? true : false;
      }

      if (btn.button == "arquivos") {
        return (item.aceitar == null) & (item.usuario_id == null)
          ? false
          : (item.aceitar == null) & (item.usuario_id != usuid)
          ? true
          : (item.aceitar == 1) & (item.usuario_id != usuid)
          ? true
          : (item.aceitar == 1) & (item.usuario_id == usuid);
      }
      if (btn.button == "aceitar") {
        return (item.aceitar == null) & (item.usuario_id == usuid)
          ? true
          : (item.aceitar == null) & (item.usuario_id == null)
          ? true
          : // item.aceitar &  usuid == item.usuario_id   ? true :
            false;
      }
    },
    sleep: function (milliseconds) {
      var start = new Date().getTime();
      for (var i = 0; i < 1e7; i++) {
        if (new Date().getTime() - start > milliseconds) {
          break;
        }
      }
    },

    async filtros() {
      let protocolos_tf = this.$store.state.protocolo.lista_protocolos_tf;
      this.lista_protocolos_tf = [];
      this.loading = true;
      let f1 = protocolos_tf.filter((item) => {
        if (!this.filtro_status) return true;
        if (this.filtro_status == "Todos") return true;

        return (
          String(item["status1"])
            .toLowerCase()
            .indexOf(this.filtro_status.toLowerCase()) !== -1
        );
      });
      // console.log(this.form_filtro.listagem)
      let f2 = f1.filter((item) => {
        if (!this.filtro_listagem) return true;
        if (this.filtro_listagem == 0) return true;

        return item["usuario_id"] === this.filtro_listagem;
      });
      let f3 = f2.filter((item) => {
        if (!this.filtro_filial) return true;
        if (this.filtro_filial.id == 0) return true;
        return item["filial_id"] === this.filtro_filial.id;
      });
      this.loading = false;

      this.lista_protocolos_tf = f3;
    },

    async acoes(item, tipo) {
      if (tipo == "editar") this.atualizar(item);
      if (tipo == "tf") {
        this.$router.push({
          name: "tranferenciaProtocolo",
          params: { protocolo_id: item.protocolo.id },
        });
      }
      if (tipo == "aceitar") {
        this.lista_protocolos_tf = [];

        await this.$store.dispatch("protocolo/clear_protocolos_tf");

        this.$store.dispatch("tf/update_tfprotocolo", {
          aceitar: 1,
          tipo_protocolo_id: item.protocolo.tipo_protocolo_id,
          protocolos_id: item.protocolos_id,
          setor_id: item.setor_id,
          data_vencimento: item.data_vencimento,
          descricao: item.descricao,
          id: item.id,
          usuario_id: UsuarioService.getUsuario()["usuario_id"],
        });

        this.listar_protocolos();
        // this.filtro_listagem = this.usuid;
        // this.filtro_status = "Todos";
        // this.filtro_filial = 0
      }

      if (tipo == "arquivos") {
        this.$router.push({
          name: "tfpArquivo",
          params: {
            tf_protocolo_id: item.id,
            protocolo_id: item.protocolos_id,
          },
        });
      }
      if (tipo == "exclusao") this.confirm(item);
    },
    async listar_protocolos() {
      this.loading = true;
      this.$store.dispatch("configEmpresa/listar_filial");

      await this.$store.dispatch("protocolo/listar_protocolos_tf");
      await this.$store.dispatch("arqtf/clear_tf_arq", null);

      this.$store.dispatch("configEmpresa/atualizar", "");
      this.filtro_listagem = this.usuid;
      this.filtro_status = "Todos";
      this.filtro_filial = { id: 0, label: "Todos" };
      this.loading = false;
    },
    atualizar(value) {
      this.$router.push({ name: "createProtocolo" });
      this.$store.dispatch("configEmpresa/atualizar", value);
    },

    async confirm(value) {
      await this.$confirm({
        title: "Tem Certeza?",
        message: `Esta ação faz Exclusao desse Protocolo no sistema?`,
        button: {
          no: "Não",
          yes: "Sim",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch("protocolo/delete_protocolo", value);
      this.fireAlert({
        ...this.mensagem_alert,
      });
    },
  },
};
</script>
  
  <style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>